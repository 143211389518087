<template>
    <div>
        <div class="container-head">
            <van-swipe class="my-swipe" :autoplay="3000" v-show="adImg">
                <van-swipe-item v-for="(image, index) in adImg" :key="index">
                    <img class="container-head-img" v-lazy="image.url" @click="handleClickAd"/>
                </van-swipe-item>
            </van-swipe>
            <img v-show="showImg" src="../assets/img/img.png" class="container-head-img">
            <div v-show="showImg" class="container-head-font1">
                <span class="container-head-title">极速缴费</span>
            </div>
            <div v-show="showImg" class="container-head-font2">
                <span class="container-head-tip">停车缴费，快人一步</span>
            </div>
        </div>
        <div class="container-above">
            <div class="container-above-carNum">
                <div>
                    <span class="container-above-carNum-font"> 车牌号</span>
                    <span class="container-above-carNum-licensePlate"
                          v-if="licensePlate == '_无_'">{{licensePlate}}</span>
                    <span class="container-above-carNum-licensePlate" v-else-if="licensePlate == null">无牌车</span>
                    <span class="container-above-carNum-licensePlate" v-else-if="licensePlate.substring(0,3) != '无牌车'">
                        {{licensePlate?licensePlate.substring(0,1)+ "·" + licensePlate.substring(1,10):''}}
                    </span>
                    <span class="container-above-carNum-licensePlate" v-else>
                        {{licensePlate}}
                    </span>
                </div>
                <div class="container-center-font">{{title}}</div>
            </div>
            <div class="container-above-info">
                <div class="container-above-info-left">
                    <span class="container-above-info-right-font">停车费用</span>
                    <div class="container-above-info-right-num">
                        {{amount ? amount : 0}}
                        <span class="container-above-info-right-y">元</span>
                    </div>
                </div>
            </div>
            <div class="container-above-splitLine"></div>
            <div class="container-above-bottom">
                <div class="container-above-bottom-font">
                    <!--                    <p>入场时间</p>-->
                    <p style="color: #82c276" @click="handleClickETC">开通ETC缴费</p>
                </div>
                <!--                <span classclass="container-above-bottom-time">-->
                <!--                    {{entranceTime ? entranceTime.substring(5,20) : ''}}-->
                <!--                </span>-->
            </div>
        </div>
        <div class="container-below">
            <div class="container-below-title">
                <span class="container-below-title-font">停车场名称</span>
                <span class="container-below-title-font">{{parkingName}}</span>
            </div>
            <div class="container-below-title">
                <span class="container-below-title-font">车道名称</span>
                <span class="container-below-title-font">{{roadName}}</span>
            </div>
            <div class="container-below-title">
                <div class="container-below-title-font">
                    <span style="padding: 2px; width: 17px; height: 17px;color: #FFFFFF;font-size: 12px;
                    background: linear-gradient(-30deg, #FB5A03, #FD4A1B);border-radius: 2px;">券</span>
                    <span style="padding: 0 3px;">优惠券</span>
                </div>
                <div v-if="sum != null && couponType != null" class="container-below-coupon-num" @click="clickCoupon">
                    {{sum}}
                    <span class="container-below-coupon-font">
                        {{couponType == 1 ? '元' : couponType == 2 ? '折' : couponType == 3 ? '全免' : ' '}}
                    </span>
                </div>
                <div v-if="sum == null || sum == '' && couponType == null || couponType == ''"
                     class="container-below-coupon-none" @click="clickCoupon">暂无可用 >
                </div>
            </div>
            <div class="container-below-splitLine"></div>
            <div class="container-below-bottom">
                <div class="container-below-bottom-font1"> 累计优惠
                    <span class="container-below-bottom-num">¥{{discountNum ? discountNum : 0}}</span>
                </div>
                <div class="container-below-bottom-font2"> 应缴
                    <span class="container-below-bottom-money">¥{{payMoney ? payMoney : 0}}</span>
                </div>
            </div>
        </div>
        <div class="abnormal-call" v-if="payType == 1">
            <div class="abnormal-call-content" @click="showBtn">
                <img src="../assets/img/call.png">
                <span>异常呼叫</span>
            </div>
        </div>
        <div class="placeholder-div"></div>
        <div class="container-bottom">
            <div class="container-bottom-left">
                <span class="container-bottom-left-num">{{payMoney ? payMoney : 0}}</span>
                <span class="container-bottom-left-font">元</span>
            </div>
            <div class="divider"></div>
            <div v-show="loading == false" @click="handleBinding" class="container-bottom-right">
                <van-button :class="loading === true ? 'container-bottom-right-font1' : 'container-bottom-right-font2'"
                            loading-type="spinner" :loading="loading"
                            loading-text="支付中...">确 定
                </van-button>
            </div>
            <div v-show="loading == true" class="container-bottom-right">
                <van-button :class="loading === true ? 'container-bottom-right-font1' : 'container-bottom-right-font2'"
                            loading-type="spinner" :loading="loading"
                            loading-text="支付中...">确 定
                </van-button>
            </div>
        </div>
        <div v-if="isShowConfirm" class="my-confirm" @click.stop="clickFun()">
            <div class="confirm-content-wrap" @click.stop>
                <h3 class="my-confirm-title">温馨提示</h3>
                <p class="my-confirm-content">呼叫管理员，是否确认呼叫？</p>
                <div class="my-operation">
                    <div class="confirm-btn" @click="clickClose()">
                        <p class="my-btn-text" @click="clickJump">确定</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay-box" v-show="showETC">
            <div class="popup-box">
                <KeyInput ref="childKey" @handleConfirmBtnFn="handleConfirmBtnFn" :plateNumber="plateNumber"/>
                <div class="popup-btn">
                    <van-button type="primary" @click="handleDefine">确 定</van-button>
                    <van-button color="#9e9e9e" type="default" @click="handleClose">取 消</van-button>
                </div>
                <div class="header-style-text">
                    <span class="p-text2">温馨提示：</span>
                    <p class="p-text2">授权车场对安装ETC的车辆在出场时实现自动扣费；<span style="color: red;">请注意，如果您的车辆没有ETC的话出场会变慢，请按照实际情况操作。</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {Button, Dialog, Icon, Swipe, SwipeItem} from 'vant';
    import {
        unlicensedOrder,
        licensePlate,
        tempPay,
        userCoupon,
        getInfo,
        getAdvertisement,
        carBinded, carBind, etcOpen, centerParkingList, queryByRoad, tempPayOrder
    } from "../api/interface";
    import bus from "../directives/bus";
    import debounce from "../directives/debounce";
    import Toast from "vant/lib/toast";
    import KeyInput from '../components/KeyInput';
    import Vue from 'vue';
    import {Lazyload} from 'vant';

    Vue.use(Lazyload);
    export default {
        name: "admissionPayment",
        components: {
            KeyInput,
            [Button.name]: Button,
            [Icon.name]: Icon,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
        },
        data() {
            return {
                dataInfo: "",
                roadId: "",
                couponName: "",
                couponType: "",
                preferential: "",
                id: "",
                sum: "",
                parkingName: "",
                roadName: "",
                licensePlate: "",
                entranceTime: "",
                amount: "",
                orderNo: "",
                parkingId: "",
                payMoney: "",
                parkingDuration: "",
                showpay1: false,
                showpay2: false,
                type: "",
                path: "",
                userId: "",
                discountNum: "",
                hours: "",
                minutes: "",
                payType: "",
                loading: false,
                showbtn: false,
                isShowConfirm: false, // 用于控制整个窗口的显示/隐藏

                weChatParameter: "",
                adImg: [],
                targeType: "",
                target: "",
                showImg: false,
                payTime: "",
                showInfo: false,
                showETC: false,
                plateNumber: "",
                title: ""
            };
        },
        created() {
            this.advertisement();
            this.userId = sessionStorage.getItem('userId');
            this.path = "/abnormalCall?userID=" + this.userId + "&roomID=" + this.$route.query.roadId;
            this.roadId = this.$route.query.roadId;
            this.parkingName = this.$route.query.parkingName;
        },
        mounted() {
            this.payType = sessionStorage.getItem('payType');
            this.getTempPayOrder();
        },
        activated() {
            bus.$on('conponInfo', function (id, name, couponType, preferential) {
                this.couponName = name;
                this.id = id;
                this.couponType = couponType;
                this.preferential = preferential;
                this.sum = preferential;
            }.bind(this));
            // if (this.id != "") {
            //     this.getUserCoupon();
            // }
            if (this.couponName != "") {
                this.getUserCoupon();
            }
        },
        methods: {
            getTempPayOrder() {
                tempPayOrder(this.$route.query.roadId).then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        if (res.data.data != null) {
                            this.parkingName = res.data.data.parkingName;
                            this.entranceTime = res.data.data.entranceTime;
                            this.amount = res.data.data.amount ? res.data.data.amount > 0 ? res.data.data.amount : 0 : 0;
                            this.roadName = res.data.data.roadName;
                            this.discountNum = res.data.data.discountAmount;
                            this.couponType = res.data.data.couponType;
                            this.id = res.data.data.couponId;
                            this.sum = res.data.data.preferential;
                            this.orderNo = res.data.data.orderNo;
                            this.parkingId = res.data.data.parkingId;
                            this.payMoney = res.data.data.realAmount ? res.data.data.realAmount > 0 ? res.data.data.realAmount : 0 : 0;
                            this.type = 1;
                            this.licensePlate = res.data.data.licensePlate ? res.data.data.licensePlate : '';
                        }
                    }
                })
            },
            advertisement() {
                let param = {
                    parkingId: this.$route.query.parkingId,
                    roadId: this.$route.query.roadId,
                    location: 6
                }
                getAdvertisement(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        if (res.data.data.materias.length > 0) {
                            this.showImg = false;
                        } else {
                            this.showImg = true
                        }
                        this.adImg = res.data.data.materias;
                        this.targeType = res.data.data.targeType;
                        this.target = res.data.data.target;
                    }
                }).catch(err => {
                    if (this.adImg) {
                        this.showImg = false;
                    } else {
                        this.showImg = true
                    }
                })
            },
            handleClickAd() {
                if (this.targeType == 0) {
                    window.location.href = this.target;
                }
            },
            handleClick() {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '无牌车缴费会覆盖有牌车的缴费信息，确认切换吗？',
                    confirmButtonColor: '#DC5040',
                }).then(() => {
                    this.getUnlicensedOrder();
                }).catch(() => {
                    Toast('已取消');
                });
            },
            showBtn() {
                this.isShowConfirm = true
            },
            clickFun() {
                this.isShowConfirm = false
            },
            clickClose() {
                this.isShowConfirm = false
            },
            clickJump() {
                this.$router.push(this.path);
            },
            clickCoupon() {
                if (this.id) {
                    this.$router.push({
                        path: '/coupon',
                        query: {carNumber: this.$route.query.licensePlate, type: this.type}
                    })
                }
            },
            getUserCoupon() {
                var _this = this;
                let param = {
                    amount: _this.amount,
                    couponId: _this.id,
                }
                userCoupon(param).then((res) => {
                    // var dataInfo = res.data.data
                    if (res.data.code == 'SUCCESS') {
                        this.discountNum = res.data.data;
                        if (_this.couponType === 3) {
                            _this.payMoney = 0;
                        } else {
                            if ((_this.amount - res.data.data).toFixed(2) < 0) {
                                _this.payMoney = 0;
                            } else {
                                _this.payMoney = (_this.amount - res.data.data).toFixed(2);
                            }
                            console.log(_this.payMoney)
                        }
                    }
                })
            },
            handleBinding() {
                if (sessionStorage.getItem('payType') == 1) {
                    let param = {
                        licensePlate: this.licensePlate,
                        openId: sessionStorage.getItem('openId'),
                        userType: sessionStorage.getItem('payType'),
                        userId: sessionStorage.getItem('userId')
                    }
                    carBinded(param).then(res => {
                        if (res.data.data == true) {
                            this.loading = true;
                            this.getTempPay();
                        } else {
                            Dialog.confirm({
                                title: '温馨提示',
                                message: '绑定车牌后您可以得到停车场出入场信息通知，停车及缴费记录更有迹可循，是否绑定？',
                                confirmButtonColor: '#DC5040',
                            }).then(() => {
                                this.loading = true;
                                this.handleCarBind();
                                this.getTempPay();
                            }).catch(() => {
                                this.handleCarBind();
                                this.loading = false;
                            });
                        }
                    })
                } else if (sessionStorage.getItem('payType') == 2) {
                    this.loading = true;
                    this.getTempPay();
                }
            },
            handleCarBind() {
                let param = {
                    licensePlate: this.licensePlate,
                    openId: sessionStorage.getItem('openId'),
                    userType: sessionStorage.getItem('payType'),
                    userId: sessionStorage.getItem('userId')
                }
                carBind(param).then(res => {
                    console.log(res.data.message)
                })
            },
            getTempPay: debounce(function () {
                let that = this;
                setTimeout(function () {
                    that.loading = false;
                }, 4000);
                let param = {
                    discount: that.discountNum,
                    couponId: that.id,
                    roadId: that.$route.query.roadId,
                    orderNo: that.orderNo,
                    parkingId: that.parkingId,
                    payMoney: that.payMoney,
                    payType: sessionStorage.getItem('payType')
                }
                tempPay(param).then((res) => {
                    // thirdChannel; //PP，创链
                    // payChannel; //1-微信，2-支付宝
                    that.weChatParameter = JSON.parse(res.data.data.payInfo);
                    if (res.data.code == 'SUCCESS') {
                        that.loading = false;
                        if (res.data.data.errorCode) {
                            if (res.data.data.errorCode == "TL_AMOUNT_ZERO") {
                                //支付地址为空和支付信息为空就直接跳转支付完成页
                                this.$router.push({
                                    path: '/pay/temp/finish', query: {
                                        parkingId: that.parkingId,
                                        parkingName: that.parkingName,
                                        amount: that.payMoney,
                                        licensePlate: that.licensePlate,
                                        payTime: that.payTime
                                    }
                                });
                            } else if (res.data.data.errorCode == "TL_PAY_EXPIRE") {
                                alert('超时，请重新扫码！')
                            }
                        } else {
                            if (res.data.data.payUrl != null) {
                                //跳转地址不为空，第三方渠道是pp或者ps就直接跳转第三方支付地址
                                if (res.data.data.thirdChannel == "pp" || res.data.data.thirdChannel == "ps") {
                                    window.location.href = res.data.data.payUrl;
                                }
                            } else if (res.data.data.payChannel == 1 && res.data.data.thirdChannel == "cl") {
                                //第三方渠道是cl，支付渠道是微信，调用微信支付
                                that.weixinPay();
                            }
                            this.orderNo = res.data.data.orderNo;
                        }
                    } else {
                        that.loading = false;
                        Toast(res.data.message)
                    }
                })
            }, 2000),
            getCurrentTime() {
                //获取当前时间并打印
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                this.payTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            },
            // 解决微信内置对象报错
            weixinPay(params) {
                let that = this;
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(params), false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(params));
                        document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(params));
                    }
                } else {
                    that.onBridgeReady();
                }
            },
            //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
            onBridgeReady() {
                let that = this;
                let timestamp = Math.round(that.weChatParameter.timeStamp).toString();
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": that.weChatParameter.appId,     //公众号名称，由商户传入
                        "timeStamp": timestamp, //时间戳，自1970年以来的秒数
                        "nonceStr": that.weChatParameter.nonceStr, //随机串
                        "package": that.weChatParameter.package,
                        "signType": that.weChatParameter.signType, //微信签名方式：
                        "paySign": that.weChatParameter.paySign, //微信签名
                    },
                    function (res) {
                        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        if (res.err_msg == "get_brand_wcpay_request:ok") { //支付成功后的操作
                            Toast("支付成功");
                        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') { //取消支付的操作
                            Toast("取消支付");
                        } else { //支付失败的操作
                            Toast("支付失败");
                        }
                    }
                );
            },
            timeFn(min) {
                //di作为一个变量传进来
                // 如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
                if (min) {
                    const hours = parseInt(min / 60 % 24);
                    const minutes = parseInt(min % 60);
                    if (hours > 0) {
                        this.hours = hours;
                    }
                    if (minutes > 0) {
                        this.minutes = minutes;
                    }
                }
            },
            handleClickETC() {
                this.showETC = true;
            },
            handleConfirmBtnFn(val) {
                this.plateNumber = val.replace(/(^\s+)|(\s+$)/g, "");
                this.plateNumber = this.plateNumber.replace(/\s/g, "");
            },
            handleClose() {
                this.$refs.childKey.handleConfirmBtnFn();
                this.showETC = false;
                this.plateNumber = "";
            },
            handleDefine() {
                this.$refs.childKey.handleConfirmBtnFn();
                let param = {
                    licensePlate: this.plateNumber,
                    open: true
                }
                etcOpen(param).then(res => {
                    this.showETC = false;
                    this.plateNumber = "";
                    if (res.data.code == 'SUCCESS') {
                        Toast.success('开通成功！');
                    }
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#f9f9f9');
            next();
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '');
            if (to.name === 'Coupon') {
                if (!from.meta.keepAlive) {
                    from.meta.keepAlive = true;
                }
                next();
            } else {
                from.meta.keepAlive = false;
                to.meta.keepAlive = false;
                next();
            }
            next();
        }
    }
</script>


<style lang="less" scoped>
    .container-head {
        margin: 10px;
        width: 95%;

        .container-head-img {
            max-width: 100%;
            max-height: 300px;
        }
    }

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        text-align: center;
    }

    .container-head-font1 {
        display: flex;
        position: absolute;
        top: 56px;
        left: 40px;
    }

    .container-head-font2 {
        display: flex;
        position: absolute;
        top: 86px;
        left: 40px;
    }

    .container-head-title {
        font-size: 23px;
        font-weight: 600;
        font-style: italic;
        color: #3A3D45;
        line-height: 13px;
    }

    .container-head-tip {
        font-size: 13px;
        font-weight: 100;
        color: #5F626B;
        line-height: 13px;
    }

    .container-above {
        margin: 10px;
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    .container-center-font {
        display: flex;
        justify-content: center;
        font-size: 13px;
        color: #555555;
        font-weight: 400;
        padding-bottom: 5px;
    }

    .container-above-tag {
        display: flex;
        justify-content: flex-end;
    }

    .container-above-tag-switch img {
        display: flex;
        width: 15px;
        height: 15px;
    }

    .container-above-tag-font {
        color: #FE4D13;
        font-size: 13px;
        font-weight: 400;
        padding: 0 0 0 3px
    }

    .container-above-carNum {
        padding: 20px 10px 10px;
        display: flex;
        justify-content: space-between;
    }

    .container-above-carNum-font {
        display: flex;
        font-size: 13px;
        color: #555555;
        font-weight: 400;
        padding-bottom: 5px;
    }

    .container-above-carNum-licensePlate {
        display: flex;
        font-size: 22px;
        color: #222222;
        font-weight: 600;
        padding-bottom: 5px;
    }

    .container-above-info {
        padding: 5px 10px 10px 10px;
        display: flex;
        /*justify-content: space-between;*/
    }

    .container-above-info-left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .container-above-info-left-font {
        display: flex;
        font-size: 13px;
        color: #555555;
        font-weight: 400;
        padding-bottom: 5px;
        margin-right: 10px;
    }

    .container-above-info-left-time {
        display: flex;
        font-size: 25px;
        color: #222222;
        font-weight: 600;
    }

    .container-above-info-left-h {
        font-size: 13px;
        padding: 8px 3px 0 3px;
    }

    .container-above-info-center {
        display: flex;
        justify-content: center;
        align-items: center
    }

    .container-above-info-center-img {
        width: 50px;
        height: 20px;
    }

    .container-above-splitLine {
        margin: 0 10px;
        border-bottom: 1px dashed #E3E3E3;
    }

    .container-above-bottom {
        padding: 10px;
    }

    .container-above-bottom-font {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #555555;
        font-weight: 400;
        padding-bottom: 5px;
    }

    .container-above-bottom-time {
        display: flex;
        font-size: 15px;
        color: #222222;
        font-weight: 600;
        padding-bottom: 5px;
    }

    .container-above-info-right-font {
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        color: #555555;
        font-weight: 400;
        padding-bottom: 5px;
        margin-right: 10px;
    }

    .container-above-info-right-num {
        display: flex;
        /*justify-content: flex-end;*/
        font-size: 25px;
        color: #222222;
        font-weight: 600;
    }

    .container-above-info-right-y {
        font-size: 13px;
        padding: 8px 3px 0 3px;
    }

    .container-below {
        margin: 20px 10px;
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    .container-below-title {
        padding: 15px 10px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .container-below-title-font {
        display: flex;
        font-size: 15px;
        color: #222222;
        font-weight: 500;
    }

    .container-below-coupon-num {
        display: flex;
        font-size: 18px;
        color: #222222;
        font-weight: 600;
    }

    .container-below-coupon-font {
        font-size: 11px;
        padding: 3px 3px 0 3px;
    }

    .container-below-coupon-none {
        font-size: 15px;
        color: #888888;
        font-weight: 500;
    }

    .container-below-splitLine {
        margin: 10px;
        background: #E3E3E3;
        height: 1px;
    }

    .container-below-bottom {
        padding: 5px 0 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end
    }

    .container-below-bottom-font1 {
        padding: 0 15px;
        display: flex;
        font-size: 15px;
        color: #222222;
        font-weight: 500;
    }

    .container-below-bottom-font2 {
        padding: 0 5px;
        display: flex;
        font-size: 15px;
        color: #222222;
        font-weight: 500;
    }

    .container-below-bottom-num {
        padding: 3px 3px 0 3px;
        color: #FE4D13;
        font-weight: 600;
    }

    .container-below-bottom-money {
        font-size: 18px;
        padding: 0 3px;
        color: #FE4D13;
        font-weight: 600;
    }

    .abnormal-call {
        position: fixed;
        bottom: 13%;
        left: 0px;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid rgb(228, 228, 228);
        box-shadow: rgba(112, 27, 10, 0.08) 0px 6px 24px 0px;
        border-radius: 0px 20px 20px 0px
    }

    .abnormal-call-content {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .abnormal-call-content img {
        width: 20px;
        height: 20px;
        padding: 0 10px;
    }

    .abnormal-call-content span {
        font-size: 10px;
    }

    .placeholder-div {
        width: 100%;
        height: 80px;
    }

    .container-bottom {
        position: fixed;
        bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background: #F9F9F9;
    }

    .container-bottom-left {
        width: 70%;
        line-height: 40px;
        background: #1F2430;
        position: relative;
        bottom: 15px;
        left: 18px;
        border-bottom-left-radius: 27px;
        border-top-left-radius: 27px;
        text-align: left;
        padding-left: 15px;
    }

    .container-bottom-left-font {
        font-size: 13px;
        font-weight: 400;
        color: #d3d3d3;
    }

    .container-bottom-left-num {
        font-size: 22px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 8px;
    }

    .container-bottom-right {
        background: #FE4D13;
        border-bottom-right-radius: 27px;
        border-top-right-radius: 27px;
        width: 30%;
        line-height: 40px;
        position: relative;
        bottom: 15px;
        right: 20px;
    }

    .container-bottom-right-font1 {
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        margin-right: 15px;
        background-color: unset !important;
        border: none !important;
        padding: unset !important;
    }

    .container-bottom-right-font2 {
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        margin-right: 15px;
        background-color: unset !important;
        border: none !important;
        padding: unset !important;
    }

    .divider {
        display: inline-block;
        width: 30px;
        background: #FE4D13;
        bottom: 0.4rem;
        right: 3px;
        position: relative;
        -webkit-transform: skew(-18deg);
    }

    .overlay-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);
    }

    .popup-box {
        position: fixed;
        top: 21%;
        left: 0;
        right: 0;
        font-size: 14px;
        background-color: #FFFFFF;
        border-radius: 10px;
        margin: 0 15px 10px 15px;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
    }

    .header-style-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #8a8a8a;
        margin: 0 33px;
        line-height: 25px;
    }

    .p-text2 {
        font-size: 12px;
        text-align: left;
    }

    .license-plate {
        padding: unset;
    }

    .license-plate /deep/ .cph-wrap-shadow {
        margin: 2px auto;
    }

    /deep/ .cph-box {
        background-color: unset;
        z-index: 0;
    }

    .popup-box /deep/ .keyboard-wrap {
        z-index: 1;
    }

    .popup-btn {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
    }

    .popup-btn .van-button {
        width: 100px;
        border-radius: 5px;
        border: none;
        font-weight: 500;
        margin: 20px 10px;
    }

    .my-confirm {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 998;
        /* 这里防止当用户长按屏幕，出现的黑色背景色块，以及 iPhone 横平时字体的缩放问题 */
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 进入和出去的动画 */
    .confirm-fade-enter-active {
        animation: opacity 0.3s;
    }

    .confirm-fade-enter-active .confirm-content-wrap {
        animation: scale 0.3s;
    }

    .confirm-fade-leave-active {
        animation: outOpacity 0.2s;
    }

    /* 包裹层容器样式 */
    .confirm-content-wrap {
        position: absolute;
        top: 28%;
        left: 0;
        right: 0;
        width: 280px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 5px;
        z-index: 999;
        user-select: none;
    }

    /* 顶部标题部分 */
    .my-confirm-title {
        padding-top: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #333;
    }

    /* 中间内容部分 */
    .my-confirm-content {
        padding: 0 15px;
        padding-top: 20px;
        margin-bottom: 32px;
        text-align: center;
        font-size: 16px;
        color: #666;
        line-height: 1.5;
    }

    /* 底部按钮样式 */
    .my-operation {
        display: flex;
        border-top: 1px solid #eee;
    }

    .my-operation .my-cancel-btn, .confirm-btn {
        flex: 1;
    }

    .my-operation .confirm-btn {
        color: #ffb000;
    }

    .my-operation .my-btn-text {
        text-align: center;
        font-size: 16px;
        margin: 8px 0;
        padding: 6px 0;
    }

    /* 其他修饰样式 */
    .my-border-right {
        border-right: 1px solid #eee;
    }

    /* 进来的动画 */
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes scale {
        0% {
            transform: scale(0);
        }
        60% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    /* 出去的动画 */
    @keyframes outOpacity {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

</style>
